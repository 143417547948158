import { render, staticRenderFns } from "./zyu-brand.vue?vue&type=template&id=14327a9a&scoped=true"
import script from "./zyu-brand.vue?vue&type=script&lang=js"
export * from "./zyu-brand.vue?vue&type=script&lang=js"
import style0 from "./zyu-brand.vue?vue&type=style&index=0&id=14327a9a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14327a9a",
  null
  
)

export default component.exports